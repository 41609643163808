import "./AssetDialog.scss"

import {Asset, Organization, Story, useNetwork} from "../util/Network"
import {useEffect, useRef, useState} from "react";
import {ReactComponent as RadioSelected} from "../assets/radio-selected.svg"
import {ReactComponent as RadioUnselected} from "../assets/radio-unselected.svg"
import {ReactComponent as Public} from "../assets/icon-public.svg"
import {ReactComponent as Private} from "../assets/icon-private.svg"
import {iconByContentType, styleByContentType} from "./Assets";

const URL_RE = /https?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/

interface AssetDialogProps {
    story?: Story
    organization?: Organization
    asset?: Asset
    close : (s?: Story, o?: Organization) => void
}

export const AssetDialog = (props: AssetDialogProps) => {
    const network = useNetwork()
    const [url, setUrl] = useState<string | undefined>(undefined)
    const [okEnabled, setOkEnabled] = useState(false)
    const [file, setFile] = useState<File | undefined>(undefined)
    const [isPublic, setIsPublic] = useState(props.asset?.public ?? false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [ellipsis, setEllipsis] = useState(3)
    const [progress, setProgress] = useState(0)
    const urlRef = useRef<HTMLInputElement>(null)
    const filenameRef = useRef<HTMLInputElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (!saving) {
            setEllipsis(3)
        } else {
            const to = setTimeout(() => {
                setEllipsis((ellipsis + 1) % 4)
            }, 250)
            return () => {clearTimeout(to)}
        }
    }, [saving, ellipsis]);

    function close(result: Story | Organization | undefined) {
        if (props.story) {
            props.close(result as Story)
        } else {
            props.close(undefined, result as Organization)
        }
    }

    function checkURL() {
        setOkEnabled(URL_RE.test(urlRef.current?.value ?? ""))
    }

    async function upload() {
        const files = inputRef.current?.files
        if (files && files.length > 0) {
            setFile(files[0])
        }
    }

    async function save() {
        if (saving)
            return
        setError(false)
        setSaving(props.asset === undefined)
        setProgress(0)
        let result : Story | Organization | undefined = undefined

        if (props.asset === undefined) {
            result = await network().uploadAsset( file ?? url ?? "", filenameRef.current?.value ?? "Asset", isPublic, props.story?.id, props.organization?.name, setProgress)
        } else if (props.story) {
            result = await network().updateAsset(props.story.id, props.asset?.id, filenameRef.current?.value, isPublic)
        } else if (props.organization) {
            result = await network().updateOrgAsset(props.organization.name, props.asset?.id, filenameRef.current?.value, isPublic)
        }
        setSaving(false)
        setProgress(0)
        if (result === undefined) {
            setError(true)
        } else {
            close(result)
        }
    }

    return  <div className="asset-dialog">
        <div className="dialog">
            {!url && !file && !props.asset ? <>
                <div className="upload" onClick={() => inputRef.current?.click()}>Browse to upload</div>
                <div className="separator">
                    <div className="line"/>OR<div className="line"/>
                </div>
                <div className="url-title">Enter URL of asset</div>
                <input type="text" placeholder="Paste URL here..." ref={urlRef} onChange={checkURL}/>
                <div className="buttons">
                    <div className="button cancel" onClick={() => close(undefined)}>Cancel</div>
                    <div className={"button ok" + (okEnabled ? "" : " disabled")} onClick={() => {if (okEnabled) {setUrl(urlRef.current?.value)}
                    }}>Ok</div>
                </div>
            </> : <>
                {saving && <>
                    <div className="loader"/>
                    <div className="progress">
                        <div className="bar" style={{width:`${Math.round(progress * 100)}%`}}/>
                    </div>
                    <div className="uploading">Your asset is uploading{".".repeat(ellipsis)}</div>
                </>}
                {props.asset && <>
                    <div className={"type-icon" + styleByContentType(props.asset.contentType)}>
                        {iconByContentType(props.asset.contentType)}
                    </div>
                    <div className="uploading">{props.asset.name}</div>
                </>}
                <div className={"filename" + (saving || props.asset ? " small" : "")}>{file?.name ?? url ?? props.asset?.url}</div>
                <div className="name-title">Asset name</div>
                <input className="file-name" defaultValue={file?.name ?? url?.replace(/.*\//, "") ?? props.asset?.name} autoFocus={true} ref={filenameRef}/>
                <div className="access">
                    <div className="option" onClick={() => setIsPublic(false)}>{isPublic ? <RadioUnselected className="radio"/> : <RadioSelected className="radio"/>} <Private/> Private</div>
                    <div className="option" onClick={() => setIsPublic(true)} >{isPublic ? <RadioSelected className="radio"/> : <RadioUnselected className="radio"/>} <Public/> Public</div>
                </div>
                <div className="buttons">
                    {error && <div className="error">There was an error uploading. Please try again.</div>}
                    <div className="button cancel" onClick={() => close(undefined)}>Cancel</div>
                    <div className={"button save"+ (saving ? " disabled" : "")} onClick={save}>Save</div>
                </div>
            </>}
        </div>
        <input className="file-input" type="file" ref={inputRef} onInput={upload} />
    </div>

}