import "./Assets.scss"
import {Asset, useNetwork, Story, Organization} from "../util/Network";
import {MouseEvent, useState} from "react";

import {ReactComponent as IconAsset} from "../assets/icon-asset.svg"
import {ReactComponent as IconAudio} from "../assets/icon-audio.svg"
import {ReactComponent as IconScript} from "../assets/icon-script.svg"
import {ReactComponent as IconVideo} from "../assets/icon-video.svg"
import {ReactComponent as IconImage} from "../assets/icon-image.svg"
import {ReactComponent as IconPDF} from "../assets/icon-pdf.svg"
import {ReactComponent as IconZip} from "../assets/icon-zip.svg"
import {ReactComponent as Private} from "../assets/private.svg"
import {ReactComponent as Archived} from "../assets/badge-archived.svg"
import {useAuthUser} from "react-auth-kit";
import {ReactComponent as Archive} from "../assets/icon-archive.svg"
import {ReactComponent as Unarchive} from "../assets/icon-unarchive.svg"
import {ReactComponent as Edit} from "../assets/icon-edit.svg"
import {ReactComponent as Remove} from "../assets/icon-remove.svg"
import {Tooltip} from "react-tooltip";
import {ReactComponent as Checkbox} from "../assets/checkbox.svg";
import {ReactComponent as CheckboxChecked} from "../assets/checkbox-checked.svg";
import {AssetDialog} from "./AssetDialog";
import {ReactComponent as Plus} from "../assets/plus.svg"
import fileDownload from 'js-file-download'

interface AssetsProps {
    story?: Story
    organization?: Organization
    setDocument?: (d: Story) => void
    setOrganization?: (o: Organization) => void
}

export const Assets = (props: AssetsProps) => {
    const user = useAuthUser()()
    const network = useNetwork()
    const [assetDialog, setAssetDialog] = useState<Asset | boolean>(false)
    const [showArchived, setShowArchived] = useState(false)

    let assets = props.story?.assets ?? props.organization?.assets ?? []
    if (!showArchived) {
        assets = assets.filter(a => !a.archived)
    }

    function close(s?: Story, o?: Organization) {
        setAssetDialog(false)
        if (s && props.setDocument)
            props.setDocument(s)
        if (o && props.setOrganization)
            props.setOrganization(o)
    }

    async function downloadAll() {
        const blob = await network().getAllAssets(props.story?.id, props.organization?.name)
        if (blob)
            fileDownload(blob, (props.story?.id ?? props.organization?.name) + ".zip")
    }

    function AssetTile(props2: { asset: Asset }) {
        const network = useNetwork()
        const user = useAuthUser()()
        const [confirmDelete, setConfirmDelete] = useState(false)

        const asset = props2.asset

        async function deleteAsset(e: MouseEvent) {
            e.stopPropagation()
            if (props.story) {
                const document = await network().deleteAsset(props.story.id, asset.id)

                if (document && props.setDocument) {
                    props.setDocument(document)
                }
            } else if (props.organization) {
                const organization = await network().deleteOrgAsset(props.organization.name, asset.id)

                if (organization && props.setOrganization) {
                    props.setOrganization(organization)
                }
            }
        }

        async function archiveAsset(e: MouseEvent) {
            e.stopPropagation()
            if (props.story) {
                network().archiveAsset(props.story.id, asset.id).then(s => {
                    if (s && props.setDocument) props.setDocument(s)
                })
            } else if (props.organization) {
                network().archiveOrgAsset(props.organization.name, asset.id).then(o => {
                    if (o && props.setOrganization) props.setOrganization(o)
                })
            }
        }


        async function unarchiveAsset(e: MouseEvent) {
            e.stopPropagation()
            if (props.story) {
                network().unarchiveAsset(props.story.id, asset.id).then(s => {
                    if (s && props.setDocument) props.setDocument(s)
                })
            } else if (props.organization) {
                network().unarchiveOrgAsset(props.organization.name, asset.id).then(o => {
                    if (o && props.setOrganization) props.setOrganization(o)
                })
            }
        }

        return <div className={"asset" + styleByContentType(asset.contentType)} onClick={() => window.open(asset.url, "_blank", "noreferrer")}>
            {iconByContentType(asset.contentType)}
            <div className="name">{asset.name}</div>
            {asset.archived && <Archived className="badge"/>}
            {!asset.archived && !asset.public && <Private className="badge"/>}
            {user?.role === "admin" && <>
                <div className="hover-buttons">
                    {asset.archived ?
                        <div className="button"
                             data-tooltip-id="button-tooltip"
                             data-tooltip-content="Unarchive"
                             data-tooltip-place="bottom"
                             onClick={unarchiveAsset}><Unarchive/></div> :
                        <div className="button"
                             data-tooltip-id="button-tooltip"
                             data-tooltip-content="Archive"
                             data-tooltip-place="bottom"
                             onClick={archiveAsset}><Archive/></div>}
                    <div className="button"
                         data-tooltip-id="button-tooltip"
                         data-tooltip-content="Edit"
                         data-tooltip-place="bottom"
                         onClick={e => {
                             setAssetDialog(asset)
                             e.stopPropagation()
                         }}><Edit/></div>
                    <div className="button"
                         data-tooltip-id="button-tooltip"
                         data-tooltip-content="Remove"
                         data-tooltip-place="bottom"
                         onClick={e => {
                             setConfirmDelete(true)
                             e.stopPropagation()
                         }}><Remove/></div>
                </div>
                <div className={"delete-confirm" + (confirmDelete ? "" : " hide")}>
                    <div className="message">Are you sure you want to remove this{styleByContentType(asset.contentType)}?</div>
                    <div className="buttons">
                        <div className="button cancel" onClick={e => {
                            setConfirmDelete(false)
                            e.stopPropagation()
                        }}>Cancel</div>
                        <div className="button delete" onClick={deleteAsset}>Remove</div>
                    </div>
                </div>

            </>}
            <Tooltip id="button-tooltip" className="tooltip"/>
        </div>
    }


    return <div className="assets">
        <div className="assets-title">
            Assets:
            {user?.role === "admin" && <div className="include-archived" onClick={_ => {setShowArchived(!showArchived)}}>
                {showArchived ? <CheckboxChecked/> : <Checkbox/>} Include archived
            </div>}
            {user?.role === "admin" && <div className="buttons">
                <div className="button" onClick={() => setAssetDialog(true)}><Plus/><div className="text">Add New</div></div>
            </div>}
        </div>
        {assets.length > 0 ? <div className="tiles">
            {assets.map(a => <AssetTile asset={a} key={a.id} />)}
            <div className="download-all" onClick={downloadAll}>Download All Assets</div>
        </div>:<div className="no-assets">
            No assets yet.
        </div>}
        {assetDialog && <AssetDialog story={props.story} organization={props.organization} close={close} asset={assetDialog === true ? undefined : assetDialog }/>}
    </div>
}


export function iconByContentType(ct: string) {
    if (ct.startsWith("audio")) return <IconAudio/>
    if (ct.startsWith("text")) return <IconScript/>
    if (ct.startsWith("video")) return <IconVideo/>
    if (ct.startsWith("image")) return <IconImage/>
    if (ct.startsWith("application/pdf")) return <IconPDF/>
    if (ct.startsWith("application/zip")) return <IconZip/>
    return <IconAsset/>
}

export function styleByContentType(ct: string) {
    if (ct.startsWith("audio")) return " audio"
    if (ct.startsWith("text")) return " text"
    if (ct.startsWith("video")) return " video"
    if (ct.startsWith("image")) return " image"
    if (ct.startsWith("application/pdf")) return " pdf"
    if (ct.startsWith("application/zip")) return " zip"

    return " asset"
}
