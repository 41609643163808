import "./AddContentDialog.scss"
import {useEffect, useState} from "react";
import {Organization, Story, useNetwork} from "../util/Network";
import {Dropdown} from "../util/Dropdown";

export const AddContentDialog = (props: {org: Organization, close: (story?:Story) => void}) => {
    const [url, setUrl] = useState<string | undefined>()
    const [comment, setComment] = useState<string | undefined>()
    const [group, setGroup] = useState<string | undefined>(props.org.groups?.at(0))
    const network = useNetwork()

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {document.body.style.overflow = 'unset' }
    }, []);

    async function add() {
        if (url) {
            const org = await network().addStory(props.org.name, url, comment, group)
            props.close(org)
        }
    }

    return <div className="add-content-dialog">
        <div className="bg" onClick={e => props.close()}/>
        <div className="dialog">
            {props.org.groups && <div className="group">
                <span className="content-group">Content group:</span>
                <Dropdown options={props.org.groups.map(g => ({value: g, label: g}))} current={group || props.org.groups[0]} onChange={setGroup}/>
            </div>}
            <div className="enter-url">Enter URL of content</div>
            <input className="url" placeholder="Paste URL here..." onChange={e => setUrl(e.target.value)}/>
            <input className="comment" placeholder="Leave a comment... (optional)" onChange={e => setComment(e.target.value)}/>
            <div className="buttons">
                <div className="button cancel" onClick={e => props.close()}>Cancel</div>
                <div className={"button ok" + (url ? "" : " disabled")} onClick={add}>Ok</div>
            </div>
        </div>

    </div>
}