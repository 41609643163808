import "./SelfWelcome.scss"
import {Dropdown} from "../../util/Dropdown";
import {useRef, useState} from "react";
import {ReactComponent as Triangle1} from "../../assets/self/triangle1.svg";
import {ReactComponent as Triangle2} from "../../assets/self/triangle2.svg";
import {ReactComponent as Triangle3} from "../../assets/self/triangle3.svg";
import {ReactComponent as Play} from "../../assets/self/play.svg";
import {ReactComponent as Pause} from "../../assets/self/pause.svg";
import heroImage from "../../assets/self/hero-image.png"
import {S, T} from "../../util/Text";

const LENGTHS = [{value: "15", label: "15 seconds"}, {value: "30", label: "30 seconds"}, {value: "60", label: "60 seconds"}]
export const SelfWelcome = (props: {mobile: boolean, create: (len: string) => void, tellus: () => void }) => {
    const [length, setLength] = useState("30")
    const [playing, setPlaying] = useState([0,false])
    const playerRef = useRef<HTMLAudioElement>(null)

    function createProject() {
        props.create(length)
    }

    function play(sample: number) {
        if (!playerRef.current)
            return
        if (sample == playing[0]) {
            if (playerRef.current.paused) {
                playerRef.current.play().then(() => {setPlaying([sample, true])})
            } else {
                playerRef.current.pause()
                setPlaying([sample, false])
            }
        } else {
            if (!playerRef.current.paused)
                playerRef.current.pause()
            playerRef.current.src = `/audio${sample}.mp3`
            playerRef.current.play().then(() => {setPlaying([sample, true])})
        }
    }

    return <div className={"welcome" + (props.mobile ? " mobile" : "")}>
        <div className="top">
            <Triangle1 className="triangle1"/>
            <img src={heroImage} className="hero" alt="hero"/>
            <T name="title"/>
            <T name="subtitle"/>
            <div className="form">
                <T name="form-title"/>
                <T name="form-subtitle"/>
                <Dropdown options={LENGTHS} current={length} onChange={setLength}/>
                <T name="create" className="button" onClick={createProject}/>
                <div className="other"><S name="different"/> <S name="tell-us" onClick={props.tellus}/></div>
            </div>
            <T name="welcome-comment"/>
        </div>
        <div className="bottom">
            <Triangle3 className="triangle3"/>
            <Triangle2 className="triangle2"/>
            <T name="completed"/>
            <T name="specialize"/>
            <div className="samples">
                <div className="sample">{playing[0] == 1 && playing[1] ? <Pause onClick={() => play(1)}/>:<Play onClick={() => play(1)}/>}<span className="text">Manscaped | :30s</span></div>
                <div className="sample">{playing[0] == 2 && playing[1] ? <Pause onClick={() => play(2)}/>:<Play onClick={() => play(2)}/>}<span className="text">NatureMade | :30s</span></div>
                <div className="sample">{playing[0] == 3 && playing[1] ? <Pause onClick={() => play(3)}/>:<Play onClick={() => play(3)}/>}<span className="text">Mint Mobile | :30s</span></div>
            </div>
            <T className="button" name="create" onClick={createProject}/>
        </div>
        <audio className="audio-player" ref={playerRef}/>
    </div>
}
