import "./Header.scss"
import {ReactComponent as Logo} from "../assets/logo-h.svg"
import {useAuthUser, useSignIn, useSignOut} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import {useNetwork} from "../util/Network";
import {ProfilePicture} from "../components/ProfilePicture";

export const Header = () => {
    const auth = useAuthUser()
    const signOut = useSignOut()
    const navigate = useNavigate()
    const network = useNetwork()
    const signIn = useSignIn()

    async function refresh() {
        const resp = await network().refreshToken()
        if (resp) {
            signIn({
                token: resp.token,
                tokenType: "Bearer",
                expiresIn: 60 * 24 * 30,
                authState: resp.user
            })
        }
    }

    function logout() {
        signOut()
        navigate("/login")
    }

    return <div className="header">
        <Logo className="logo" onClick={() => navigate("/")}/>
        <div className="logout" onClick={logout}>Sign out</div>
        <div onClick={refresh}>
            <ProfilePicture image={auth()?.image} uid={auth()?.uid} />
        </div>
    </div>
}