import './Playlist.scss'

import React, {createRef, useEffect, useState} from 'react'
import {ReactComponent as Close} from "../assets/playlist/icon-close.svg";
import {ReactComponent as Comments} from "../assets/playlist/icon-comments-black.svg";
import {ReactComponent as VolumeOn} from "../assets/playlist/icon-volumeOn.svg";
import {ReactComponent as VolumeOff} from "../assets/playlist/icon-volumeOff.svg";
import {ReactComponent as Like} from "../assets/playlist/icon-like.svg";
import {ReactComponent as Liked} from "../assets/playlist/icon-unlike.svg";
import {ReactComponent as Share} from "../assets/playlist/icon-share.svg";
import {ReactComponent as ConvClose} from "../assets/playlist/close.svg";
import playAnimation from '../assets/playlist/play.json'
import pauseAnimation from '../assets/playlist/pause.json'

import axios from "axios";
import {useLocation, useParams} from "react-router-dom";
import {useLocalStorage} from "../util/LocalStorage";
import {useLottie} from "lottie-react"

export function Playlist() : JSX.Element {
    const params = useParams()
    const plid = params['id']
    const { search } = useLocation()
    const id = new URLSearchParams(search).get('id')

    const items = plid === 'sl' ? [
        'https://storage.googleapis.com/spokenlayerstudio/Demo1.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo2.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo3.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo4.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo5.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo6.mp4',
    ] : [
        'https://storage.googleapis.com/spokenlayerstudio/Demo1.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo2.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo3.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo4.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo5.mp4',
        'https://storage.googleapis.com/spokenlayerstudio/Demo6.mp4',
    ]

    const titles = plid === 'sl' ? [
        'SBF claims massive ignorance on obvious conflicts in FTX downfall',
        'Spotify Wrapped 2022 arrives with new features like your \'Listening Personality,\' 40K+ Artist Messages',
        'Hacker George Hotz, long a frenemy of Elon Musk, signs on for 12 weeks at Twitter',
        'DoorDash rolls out new safety features for delivery people on its platform',
        'DoorDash rolls out new safety features for delivery people on its platform',
        'The recalls keep rolling in from Tesla'
    ] : [
        'DoorDash is laying off more than a thousand people in an effort to rein in costs.',
        'The recalls keep rolling in from Tesla',
        'Biden signs bill avoiding a rail strike during holidays',
        'Biden signs bill avoiding a rail strike during holidays',
        'Biden signs bill avoiding a rail strike during holidays',
        'Tesla Semi Truck Drives 500 Miles Fully Loaded'
    ]

    const [playing, setPlaying] = useState(true)
    const [mute, setMute] = useState(true)
    const [count, setCount] = useState<number | undefined>(undefined)
    const [current, setCurrent] = useState(id ? items.indexOf(id) : 0)
    const [showComments, setShowComments] = useState(false)
    const [likes, setLikes] = useLocalStorage<Array<string>>('PlLikes', [])
    const [showAnimation, setShowAnimation] = useState<'play' | 'pause' | undefined>(undefined)
    const [, forceUpdate] = useState(0)
    const refs = items.map(() => createRef<HTMLVideoElement>())
    const animation = useLottie({ autoplay: true, loop: false, animationData: showAnimation === 'play' ? playAnimation : pauseAnimation }).View

    useEffect(() => {
        const prev = document.body.style.overflow
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = prev
        }
    })

    useEffect(() => {
        updateCount()
        const interval = setInterval(() => {updateCount()}, 60000)
        return () => clearInterval(interval)
    })

    function updateCount() {
        axios.get(`https://open-api.spot.im/v1/messages-count?spot_id=sp_fjvMpMEh&posts_ids=${items[current]}`).then(r => {
            setCount(r.data.messages_count[items[current]])
        }).catch(e => console.error(e))
    }

    // async function changeIndex(newI: number, oldI: number) {
    //     if (playing) {
    //         refs[oldI].current?.pause()
    //         const n = refs[newI].current
    //         if (n) {
    //             await n.play().catch(e => alert(e))
    //         }
    //     }
    //     setCurrent(newI)
    // }

    async function playPause() {
        await (playing ? refs[current].current?.pause() : refs[current].current?.play())
        setPlaying(!playing)
        setShowAnimation(playing ? 'pause' : 'play')
    }

    async function muteUnmute() {
        refs.forEach(r => {
            const c = r.current
            if (c)
                c.muted = !mute
        })
        setMute(!mute)
    }

    function toggleShowComments() {
        setShowComments(!showComments)
    }

    function toggleLike() {
        setLikes((l) => {
            if (l.includes(items[current]))
                return l.filter(i => i !== items[current])
            else {
                l.push(items[current])
                return l
            }
        })
        forceUpdate(Math.random)
    }

    async function share() {
        if (navigator.share === undefined) {
            // can't share on desktop yet
            alert('share only works on mobile ')
            return
        } else {
            await navigator.share({
                title: titles[current],
                text: `Check out this video: “${titles[current]}”`,
                url: `https://www.ausum.fm/openweb/mobile?id=${items[current]}`
            })
        }
    }

    return <div className='video-playlist2'>
        {/*<SwipeableViews axis='y' containerStyle={ {height: '100vh'} } slideStyle={ {height: '100vh', overflow: 'hidden'} }*/}
        {/*                onChangeIndex={changeIndex} disabled={showComments} index={id ? items.indexOf(id) : undefined}>*/}
            {items.map((item, i) =>
                <video loop autoPlay muted playsInline className='video-container' key={item} onClick={playPause}
                       style={ {height: '100vh', width: '100vw', padding: 0} } ref={refs[i]}>
                    <source src={item} type="video/mp4"/>
                </video>
            )}
        {/*</SwipeableViews>*/}
        <div className='overlay'>
            <div className='button close'><Close/></div>
            <div className='button volume' onClick={muteUnmute}>{mute ? <VolumeOff/> : <VolumeOn/>}</div>

            {/*<div className='button playing' onClick={playPause}>{playing ? <Pause/> : <Play/>}</div>*/}
            <div className='bottom'>
                <div className='button share' onClick={share}><Share/></div>
                <div className='button like' onClick={toggleLike}>{likes.includes(items[current]) ? <Liked/> : <Like/>}</div>
                {(count !== undefined) && <div className='comments' onClick={toggleShowComments}>
                    <Comments/>See {count} Comments
                </div>}
            </div>
        </div>
        <div className='play-pause'>
            {showAnimation && animation}
        </div>
        <div className={'conversation' + (showComments ? ' shown' : '')} onClick={e => e.stopPropagation()}>
            <div className='header' onClick={toggleShowComments}>
                <ConvClose/>
            </div>
            <div data-spotim-module="conversation" data-post-url={`https://studio.ausum.io/${items[current]}/player`} data-post-id={items[current]}></div>
        </div>
    </div>
}
