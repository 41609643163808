import "./UserManager.scss"
import {useEffect, useRef, useState} from "react";
import {useNetwork, User} from "../util/Network";
import {ProfilePicture} from "./ProfilePicture";

export const UserManager = (props: {org: string, close: () => void}) => {
    const emailRE = /^\S+@\S+\.\S+$/
    const [users, setUsers] = useState<Array<User> | undefined>()
    const [email, setEmail] = useState<string | undefined>()
    const emailRef = useRef<HTMLInputElement>(null)
    const network = useNetwork()

    useEffect(() => {
        network().getUsers(props.org).then(setUsers)
    }, []);

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {document.body.style.overflow = 'unset' }
    }, []);

    async function add() {
        if (email && emailRE.test(email)) {
            await network().addUser(props.org, email)
            network().getUsers(props.org).then(setUsers)
            if (emailRef.current)
                emailRef.current.value = ""
        }
    }

    return <div className="user-manager">
        <div className="bg" onClick={props.close}/>
        <div className="dialog">
            <div className="title">Manage Users</div>
            <div className="add-user">Add User</div>
            <input className="user-email" placeholder="Enter user email..." onChange={e => setEmail(e.target.value)} ref={emailRef}/>
            <div className="list">
                {users?.map(u =>
                    <div className="user" id={u.id}>
                        <ProfilePicture image={u.image} uid={u.id} className="profile"/>
                        <div className="details">
                            <div className="name">{u.name}</div>
                            <div className="email">{u.email}</div>
                        </div>
                    </div>)}
            </div>
            <div className="buttons">
                <div className="button cancel" onClick={props.close}>Cancel</div>
                <div className={"button add" + (emailRE.test(email??"") ? "" : " disabled")} onClick={add}>Add User</div>
            </div>
        </div>

    </div>
}