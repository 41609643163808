import "./Dropdown.scss"

import {ReactComponent as DropDownArrow} from "../assets/dropdown-arrow.svg"
import {useState} from "react";
interface DropdownProps {
    options: Array<{value: string, label: string}>
    current: string
    onChange: (v: string) => void
}
export const Dropdown = (props: DropdownProps) => {
    const [open, setOpen] = useState(false)


    return <div className={"Dropdown" + (open ? " open" : "")} onClick={_ => setOpen(!open)}>
        {props.options.find(o => o.value === props.current)?.label}
        <DropDownArrow/>
        {open && <div className="options">
            {props.options.map(o =>
                <div className={"option" + (o.value === props.current ? " selected" : "")} onClick={_ => props.onChange(o.value)}>{o.label}</div>
            )}

        </div>}
    </div>

}