import "./EmptyState.scss"
import {Header} from "./Header";
import React from "react";

export const EmptyState = () => {

    return <div className="emptyState">
        <Header/>
        <div className="message">
            We haven’t started any projects for you.<br/>
            If you have questions or need help,<br/>
            <span className="contact">Please contact support.</span>
        </div>
    </div>
}