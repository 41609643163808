import "./Root.scss"
import {Header} from "./Header";
import React, {useEffect, useState} from "react";
import {Organization, useNetwork} from "../util/Network";
import {useAuthUser} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "react-tooltip";

export const Root = () => {
    const network = useNetwork()
    const navigate = useNavigate()
    const [organizations, setOrganizations] = useState<Array<Organization>>([])
    const user = useAuthUser()()

    useEffect(() => {
        if (user?.role === "admin")
            network().getOrganizations().then((o) => {if (o) setOrganizations(o)})
        else {
            if (user?.proj)
                navigate(`/o/${user.proj}`)
            else
                navigate(`/es`)
        }
    }, [])

    return <div className="root">
        <Header/>
        <div className="canvas">
            {organizations.sort((a, b) => a.name.localeCompare(b.name)).map(o => <Org key={o.name} org={o}/> )}
        </div>
    </div>
}

const Org = (props: {org: Organization}) => {
    const navigate = useNavigate()
    const total = Object.values(props.org.counts).reduce((a,v) => a + v, 0)
    const tooltip =
        `<div><span class="label">Not Started</span>${props.org.counts.not_started ?? 0}</div>` +
        `<div><span class="label">In Review</span>${props.org.counts.in_review ?? 0}</div>` +
        `<div><span class="label">In Progress</span>${props.org.counts.in_progress ?? 0}</div>` +
        `<div><span class="label">Delivered</span>${props.org.counts.delivered ?? 0}</div>`

    return <div className="org" key={props.org.name}
    style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.70) 100%), ${props.org.color ?? "#ED1D24"}`}}
                onClick={() => navigate(`/o/${props.org.name}`)}>
        <div className="name">{props.org.name}</div>
        <div className="count"
             data-tooltip-id="stories-tooltip"
             data-tooltip-html={tooltip}
             data-tooltip-place="right-start">{total} Stories</div>
        <Tooltip id="stories-tooltip" className="tooltip"/>
    </div>
}