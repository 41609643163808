import "./Organization.scss"
import {Header} from "./Header";
import React, {useEffect, useState} from "react";
import {Organization, Story, useNetwork} from "../util/Network";
import {ProjectCard} from "../components/ProjectCard";
import {ReactComponent as UserPlus} from "../assets/user-plus.svg"
import {ReactComponent as AddContent} from "../assets/icon-addContent.svg"
import {useAuthUser} from "react-auth-kit";
import {UserManager} from "../components/UserManager"
import {useNavigate, useParams} from "react-router-dom";
import {Dropdown} from "../util/Dropdown";
import {Assets} from "../components/Assets";
import {AddContentDialog} from "../components/AddContentDialog";

const StatusFilterOptions = [
        {value: "all", label:"All Statuses"},
        {value: "not_started", label:"Not Started"},
        {value: "in_review", label:"In Review"},
        {value: "in_progress", label:"In Progress"},
        {value: "delivered", label:"Delivered"}
]

const SortFilterOptions = [
    {value: "createdAsc", label: "Oldest to newest"},
    {value: "createdDesc", label: "Newest to oldest"},
]

export const OrganizationPage = () => {
    const network = useNetwork()
    const navigate = useNavigate()
    const [organization, setOrganization] = useState<Organization|undefined>(undefined)
    const [userManager, setUserManager] = useState<string | undefined>(undefined)
    const [addContent, setAddContent] = useState<Organization | undefined>(undefined)
    const user = useAuthUser()()
    const id = useParams().id ?? ""
    const [statusFilter, setStatusFilter] = useState(localStorage.getItem("status-filter") ?? "all")
    const [sortFilter, setSortFilter] = useState(localStorage.getItem("sort-filter") ?? "createdDesc")

    useEffect(() => {
        if (id)
            network().getOrganization(id).then(setOrganization)
    }, [])

    useEffect(() => {
        localStorage.setItem("status-filter", statusFilter)
        localStorage.setItem("sort-filter", sortFilter)
    }, [statusFilter, sortFilter]);

    async function updateDocument(id: string, title?: string, image?: string) {
        if (title || image) {
            const doc = await network().updateStory(id, title, image)

            setOrganization(p => {
                if (p) {
                    return ({...p, documents: (p?.documents ?? []).map(d => (d.id === doc?.id) ? doc : d) })
                } else {
                    return undefined
                }
            })
        }
    }

    function deleteDocument(id: string) {
        network().deleteStory(id).then(setOrganization)
    }

    function addContentClose(story?: Story) {
        if (story) {
            network().getOrganization(id).then(setOrganization)
        }
        setAddContent(undefined)
    }

    const filtered = (statusFilter === "all" ? organization?.documents :
        organization?.documents?.filter(d => (d.status === statusFilter) || (statusFilter === "not_started" && !d.status))) ?? []
    const sorted = sortFilter === "createdAsc" ? filtered.sort((a,b) => a.id.localeCompare(b.id)) : filtered.sort((a,b) => -a.id.localeCompare(b.id))

    const groups = Array.from(new Set(sorted.map(d => d.group || undefined))).sort()

    if (groups.includes(undefined)) {
        groups.pop()
        groups.unshift(undefined)
    }

    return <div className="organization">
        <Header/>
        {user?.role === "admin" && <div className="home" onClick={() => navigate("/")}>Home  »</div>}
        {organization && <div className="canvas">
            <div className="project">
                <div className="org">
                    <div className="name">{organization.name}</div>
                    {user?.role === "admin" &&
                        <div className="manage-users" onClick={() => setUserManager(organization.name)}><UserPlus/>Manage Users</div>
                    }
                    <div className="add-content" onClick={() => setAddContent(organization)}><AddContent/>Add Content</div>
                </div>
                <Assets organization={organization} setOrganization={setOrganization}/>
                <div className="filters">
                    <div className="sort-filter filter">Sort by: <Dropdown options={SortFilterOptions} current={sortFilter} onChange={setSortFilter}/></div>
                    <div className="show-filter filter">Show: <Dropdown current={statusFilter} onChange={setStatusFilter} options={StatusFilterOptions}/></div>
                </div>
                {groups.length <= 1 ? <div className="cards">
                    {sorted.map(document => <ProjectCard key={document.id} story={document} updateDocument={updateDocument} deleteDocument={deleteDocument}/>)}
                </div> : groups.map(g => <>
                    <div className="group-name" id={"name-" + g}>{g}</div>
                    <div className="cards" id={"cards-" + g}>
                        {sorted.filter(d => d.group === g).map(document => <ProjectCard key={document.id} story={document} updateDocument={updateDocument} deleteDocument={deleteDocument}/>)}
                    </div>
                </>)}
            </div>
        </div>}
        {userManager && <UserManager org={userManager} close={() => setUserManager(undefined)}/>}
        {addContent && <AddContentDialog org={addContent} close={addContentClose}/>}
    </div>
}