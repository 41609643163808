import "./Images.scss"
import React, {useEffect, useRef, useState} from "react";
import {Dropdown} from "../util/Dropdown";
import logo from "../assets/logo.png"
import fileDownload from 'js-file-download'
import { ChromePicker } from 'react-color'

export const Images = () => {
    const [image, setImage] = useState<ImageBitmap>()
    const [dropValid, setDropValid] = useState<boolean | undefined>(undefined)
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const context = canvasRef.current?.getContext("2d")
    const [bg, setBg] = useState<string>("transparent")
    const [custom, setCustom] = useState("FFFFFF")
    const [scale, setScale] = useState("95")
    const [picker, setPicker] = useState(false)

    useEffect(() => {
        if (image && context) {
            const w = canvasRef.current?.width || 0
            const h = canvasRef.current?.height || 0
            context.fillStyle = bg === "custom" ? ("#" + custom) : bg
            context.fillRect(0, 0, w, h)
            const ar = image.width / image.height
            const s = Number(scale) / 100
            const iw = ((ar >= 1) ? w : w * ar) * s
            const ih = ((ar >= 1) ? h / ar : h) * s
            context.drawImage(image, (w - iw) / 2, (h - ih)/2, iw, ih)
        }
    }, [image, bg, context, custom, scale]);

    function drag(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault()
        if (e.type === 'dragleave')
            setDropValid(undefined)
        else if (e.type === 'dragenter')
            setDropValid(e.dataTransfer.items.length === 1 && e.dataTransfer.items[0].kind === "file" && e.dataTransfer.items[0].type.startsWith("image/"))
    }

    async function drop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault()
        if (e.dataTransfer.items.length === 1 && e.dataTransfer.items[0].kind === "file" && e.dataTransfer.items[0].type.startsWith("image/")) {
            setImage( await e.dataTransfer.items[0].getAsFile()?.arrayBuffer()?.then(f => createImageBitmap(new Blob([f]))))
        } else {
            setDropValid(undefined)
        }
    }

    function download() {
        canvasRef.current?.toBlob(b => {
            if (b) {
                fileDownload(b, "image.jpg")
            }
        }, "image/jpeg")
    }

    return <div className="images">
        <div className="title"><img src={logo} alt="logo"/> Spokenlayer Image Tools</div>
        <div className={"drop" + (dropValid ? " valid" : (dropValid === false ? " invalid" : "")) + (image ? " hide" : "")} onDragEnter={drag} onDragLeave={drag} onDragOver={drag} onDrop={drop}>
            <div>Drop image file here.</div>
            {dropValid === false && <div>File format invalid. Please use a supported file type.</div>}
        </div>
        <canvas className={"canvas" + (image ? "" : " hide")} ref={canvasRef} width={640} height={640}/>
        <div className="bg">
            Background color: <Dropdown options={[{value: "transparent", label: "Transparent"},{value: "#FFFFFF", label: "White"},{value: "#000000", label: "Black"},{value: "custom", label: "Custom"}]} current={bg} onChange={setBg}/>
            {bg === "custom" && <>
                <input value={custom} onChange={e => setCustom(e.target.value)}/>
                <svg width={50} height={50} onClick={_ => setPicker(!picker)}>
                    <circle cx={25} cy={25} r={15} stroke="#000" strokeWidth={1} fill={"#" + custom}/>
                </svg>
                {picker && <ChromePicker color={"#" + custom} onChangeComplete={e => setCustom(e.hex.substring(1))} disableAlpha={true}/>}
            </>}
        </div>
        <div className="scale">
            Scale: <input type="range" value={scale} onChange={e => setScale(e.target.value)}/>
        </div>
        <div className={"button" + (image ? "" : " disabled")} onClick={download}>Download</div>
    </div>
}