import axios from "axios"
import {useAuthHeader} from "react-auth-kit"
import {fromUint8Array} from "js-base64"

const URL_BASE = (process.env['REACT_APP_BASE'] ?? '/') + 'a/'
const MAX_UPLOAD_SIZE = 20 * 1024 * 1024 // 20MB

export interface User {
    id: string
    image: string
    name: string
    email: string
    notifications: Map<string, number>
}

export interface LoginResponse {
    user: User
    token: string
}

class Network {
    token: string;

    constructor(token: string) {
        this.token = token
    }

    async login(email: string, password: string) : Promise<LoginResponse | undefined> {
        const response = await axios.post(URL_BASE + "login", {email, password}, {validateStatus: null})
        if (response.status < 300)
            return response.data
        else
            return undefined
    }

    async googleLogin(credentials: string) : Promise<LoginResponse | undefined> {
        const response = await axios.post(URL_BASE + "login", {google: credentials}, {validateStatus: null})
        if (response.status < 300)
            return response.data
        else
            return undefined
    }

    async resetPassword(key: string, password: string) : Promise<LoginResponse | undefined> {
        const response = await axios.post(URL_BASE + "password", {key, password}, {validateStatus: null})
        if (response.status < 300)
            return response.data
        else
            return undefined
    }

    async forgotPassword(email: string) : Promise<undefined> {
        const response = await axios.post(URL_BASE + "resetPassword", {email}, {validateStatus: null})
        if (response.status < 300)
            return response.data
        else
            return undefined

    }

    async refreshToken(): Promise<LoginResponse | undefined> {
        return this.get("refresh")
    }

    async getOrganizations() : Promise<Array<Organization> | undefined> {
        return (await this.get("org"))
    }

    async getOrganization(org: string) : Promise<Organization | undefined> {
        return (await this.get(`org/${org}`))
    }

    async uploadAsset(asset: File | string, name: string, isPublic: boolean, docId?: string, orgId?: string, updateProgress?: (n: number) => void) : Promise<Story | Organization | undefined> {
        if (asset instanceof File) {
            const data = await asset.arrayBuffer()
            if (data.byteLength > MAX_UPLOAD_SIZE) {
                let start = 0
                let parts : Array<string> = []
                while (start < data.byteLength) {
                    const end = Math.min(start + MAX_UPLOAD_SIZE, data.byteLength)
                    const res = await this.post<{name: string}>("uploadAssetPart", data.slice(start, end), updateProgress ? (p) => updateProgress((start + p * (end - start)) / data.byteLength) : undefined)
                    if (res) {
                        parts.push(res.name)
                    } else {
                        return undefined
                    }
                    start = end
                }
                return this.post("uploadAsset", {
                    document: docId,
                    organization: orgId,
                    name: name,
                    contentType: asset.type,
                    public: isPublic,
                    parts: parts
                }, updateProgress)
            } else {
                return this.post("uploadAsset", {
                    document: docId,
                    organization: orgId,
                    name: name,
                    contentType: asset.type,
                    public: isPublic,
                    data: fromUint8Array(new Uint8Array(data))
                }, updateProgress)
            }
        } else {
            return this.post('uploadAssetURL', {document: docId, organization: orgId, url: asset, name: name, public: isPublic})
        }
    }

    async updateAsset(docId: string, assetId: string, name?:string, isPublic?: boolean) : Promise<Story | undefined> {
        return this.put(`doc/${docId}/asset/${assetId}`, {name, public: isPublic})
    }

    async deleteAsset(docId: string, assetId: string) : Promise<Story | undefined> {
        return this.delete(`doc/${docId}/asset/${assetId}`)
    }

    async archiveAsset(docId: string, assetId: string) : Promise<Story | undefined> {
        return this.post(`doc/${docId}/asset/${assetId}/archive`, {})
    }

    async unarchiveAsset(docId: string, assetId: string) : Promise<Story | undefined> {
        return this.post(`doc/${docId}/asset/${assetId}/unarchive`, {})
    }

    async getAllAssets(docId?: string, orgId?: string) : Promise<ArrayBuffer | undefined> {
        const url = docId ? URL_BASE + `doc/${docId}/assets` : URL_BASE + `org/${orgId}/assets`
        const response = await axios.get(url,
            {headers: {Authorization: this.token}, validateStatus: null, responseType: "arraybuffer"})
        if (response.status < 300) {
            return response.data
        } else {
            return undefined
        }
    }

    async updateOrgAsset(orgId: string, assetId: string, name?:string, isPublic?: boolean) : Promise<Organization | undefined> {
        return this.put(`org/${orgId}/asset/${assetId}`, {name, public: isPublic})
    }

    async deleteOrgAsset(orgId: string, assetId: string) : Promise<Organization | undefined> {
        return this.delete(`org/${orgId}/asset/${assetId}`)
    }

    async archiveOrgAsset(orgId: string, assetId: string) : Promise<Organization | undefined> {
        return this.post(`org/${orgId}/asset/${assetId}/archive`, {})
    }

    async unarchiveOrgAsset(orgId: string, assetId: string) : Promise<Organization | undefined> {
        return this.post(`org/${orgId}/asset/${assetId}/unarchive`, {})
    }

    async updateStory(id: string, title?: string, image?: string) : Promise<Story | undefined> {
        return this.post(`doc/${id}`, {title, image})
    }

    async setStatus(id: string, status: string) : Promise<Story | undefined> {
        const response = await axios.post<Story>(URL_BASE + "doc/" + id, {status}, {headers: {Authorization: this.token}, validateStatus: null})
        if (response.status < 300) {
            return response.data
        } else {
            return undefined
        }
    }

    async setDue(id: string, due: number) : Promise<Story | undefined> {
        const response = await axios.post<Story>(URL_BASE + "doc/" + id, {due}, {headers: {Authorization: this.token}, validateStatus: null})
        if (response.status < 300) {
            return response.data
        } else {
            return undefined
        }
    }

    async getDocument(id: string) : Promise<Story | undefined> {
        return this.get(`doc/${id}`)
    }

    async deleteStory(id: string) : Promise<Organization | undefined> {
        return this.delete(`doc/${id}`)
    }

    async addComment(id: string, text: string) : Promise<Story | undefined> {
        return this.post(`doc/${id}/comment`, {text})
    }

    async deleteComment(docId: string, commentId: string) : Promise<Story | undefined> {
        return this.delete(`doc/${docId}/comment/${commentId}`)
    }

    async updateComment(docId: string, commentId: string, currentText: string, newText: string) : Promise<Story | undefined> {
        return this.put(`doc/${docId}/comment/${commentId}`, {currentText, newText})
    }

    async getUsers(org: string) : Promise<Array<User> | undefined> {
        return this.get<{users: Array<User>}>(`users/${org}`).then(r => r?.users)
    }

    async addUser(org: string, email:string) : Promise<Array<User> | undefined>{
        return this.post<{users: Array<User>}>(`users/${org}/add`, {email}).then(r => r?.users)
    }

    async addStory(org: string, url: string, comment?: string, group?: string) : Promise<Story | undefined> {
        return this.post(`org/${org}/story`, {url, comment, group})
    }

    async startPayment(items: Array<{sku: string, count: number}>) : Promise<string|undefined> {
        return this.post<{ClientSecret: string}>('payments', {items}).then(r => r?.ClientSecret)
    }

    userImageUrl(uid: string) : string {
        return `${URL_BASE}user/${uid}/image`
    }

    private async get<T>(path: string) : Promise<T | undefined> {
        const response = await axios.get<T>(URL_BASE + path, {headers: {Authorization: this.token}, validateStatus: null})
        if (response.status < 300) {
            return response.data
        } else {
            return undefined
        }
    }

    private async post<T>(path: string, data: any, updateProgress?: (p: number) => void) : Promise<T | undefined> {
        const response = await axios.post<T>(URL_BASE + path, data,
            {headers: {Authorization: this.token}, validateStatus: null,
            onUploadProgress: (e) => {if (updateProgress && e.progress) updateProgress(e.progress)}})
        if (response.status < 300) {
            return response.data
        } else {
            return undefined
        }
    }

    private async put<T>(path: string, data: any) : Promise<T | undefined> {
        const response = await axios.put<T>(URL_BASE + path, data, {headers: {Authorization: this.token}, validateStatus: null})
        if (response.status < 300) {
            return response.data
        } else {
            return undefined
        }
    }

    private async delete<T>(path: string) : Promise<T | undefined> {
        const response = await axios.delete<T>(URL_BASE + path, {headers: {Authorization: this.token}, validateStatus: null})
        if (response.status < 300) {
            return response.data
        } else {
            return undefined
        }
    }
}

export function useNetwork() : () => Network {
    const authHeader = useAuthHeader()
    return () => {
        return new Network(authHeader().substring(7))
    }
}

export interface Organization {
    name: string
    documents: Array<Story>
    assets: Array<Asset>
    color: string
    counts: {
        not_started: number
        in_review: number
        in_progress: number
        delivered: number
    }
    groups: Array<string>
}

export interface Story {
    id: string
    url: string
    title: string
    project: string
    image?: string
    status?: string
    assets?: Array<Asset>
    comments?: Array<Comment>
    due?: number
    group?: string
}

export interface Asset {
    id: string
    name: string
    contentType: string
    url: string
    public: boolean
    archived?: number
}

export interface Comment {
    id: string
    text: string
    timestamp: number
    user: string
    userName: string
}