import './ProjectCard.scss'

import {Story} from "../util/Network"
import {useAuthUser} from "react-auth-kit"
import {useNavigate} from "react-router-dom"
import {Img} from "react-image"
import {STATUSES} from "./Status"
import {formatInTimeZone} from "date-fns-tz"
import {ReactComponent as Edit} from "../assets/icon-edit.svg"
import {ReactComponent as Remove} from "../assets/icon-remove.svg"
import {MouseEvent, useRef, useState} from "react"

interface StoryCardProps {
    story: Story
    updateDocument: (id: string, title?: string, image?: string) => void
    deleteDocument: (id: string) => void
}

export const ProjectCard = (props: StoryCardProps) => {
    const notifications = useAuthUser()()?.notifications
    const counter = notifications ? (notifications[props.story.id] ?? 0) : 0
    const navigate = useNavigate()
    const user = useAuthUser()()
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [editDialog, setEditDialog] = useState(false)
    const titleRef = useRef<HTMLInputElement>(null)
    const imageRef = useRef<HTMLInputElement>(null)

    function deleteStory(e: MouseEvent) {
        e.stopPropagation()
        props.deleteDocument(props.story.id)
    }

    function save() {
        const newTitle = titleRef.current?.value === props.story.title ? undefined : titleRef.current?.value
        const newImage = imageRef.current?.value === props.story.image ? undefined : imageRef.current?.value
        props.updateDocument(props.story.id, newTitle, newImage)
        setEditDialog(false)
    }

    return <div className='item' onClick={() => navigate(`/d/${props.story.id}`)}>
        {user?.role === "admin" && <div className="hover-buttons">
            <div className="button"
                 data-tooltip-id="button-tooltip"
                 data-tooltip-content="Edit"
                 data-tooltip-place="bottom"
                 onClick={e => {
                     setEditDialog(true)
                     e.stopPropagation()
                 }}><Edit/></div>
            <div className="button"
                 data-tooltip-id="button-tooltip"
                 data-tooltip-content="Remove"
                 data-tooltip-place="bottom"
                 onClick={e => {
                     setConfirmDelete(true)
                     e.stopPropagation()
                 }}><Remove/></div>
        </div>}
        <Img className='image' src={[props.story.image ?? "/placeholder.png", "/placeholder.png"]} alt='background'/>
        <div className='gradient'/>
        <span className='title'>{props.story.title}</span>
        <div className='status-container'>
            <span
                className={`status ${props.story.status ?? 'not_started'}`}>{STATUSES.get(props.story.status ?? 'not_started')}</span>
        </div>
        {user?.role === "admin" && props.story.due && props.story.status !== "delivered" && <div className="due">
            Due {formatInTimeZone(new Date(props.story.due * 1000), "UTC", "MMM dd")}
        </div>}
        {counter > 0 && <div className="counter">{counter}</div>}
        {confirmDelete && <div className="delete-confirm">
            <div className="message">Are you sure you want to remove this story?</div>
            <div className="buttons">
                <div className="button cancel" onClick={e => {
                    setConfirmDelete(false)
                    e.stopPropagation()
                }}>Cancel
                </div>
                <div className="button delete" onClick={deleteStory}>Remove</div>
            </div>
        </div>}
        {editDialog && <div className="edit-dialog">
            <div className="bg" onClick={_ => setEditDialog(false)}/>
            <div className="dialog" onClick={e => e.stopPropagation()}>
                <div className="edit-title">{props.story.title}</div>
                <div className="url">{props.story.url}</div>
                <div className="story-title-header">Story Title</div>
                <input className="story-title" defaultValue={props.story.title} ref={titleRef}/>
                <div className="story-image-header">Story Image</div>
                <input className="story-image" defaultValue={props.story.image} ref={imageRef}/>
                <div className="buttons">
                    <div className="button cancel" onClick={_ => setEditDialog(false)}>Cancel</div>
                    <div className="button save" onClick={save}>Save</div>
                </div>
                <div className="image">
                    <img src={props.story.image} alt="cover"/>
                    <div className="gradient"/>
                </div>
            </div>
        </div>}
    </div>
}
