import React, {useEffect, useState} from "react";
import "./SelfServe.scss"
import {SelfHeader} from "../components/selfserve/SelfHeader";
import {SelfFooter} from "../components/selfserve/SelfFooter";
import {SelfWelcome} from "../components/selfserve/SelfWelcome";
import {SelfTellUs} from "../components/selfserve/SelfTellUs";
import {SelfCreate} from "../components/selfserve/SelfCreate";
import {useNavigate, useParams} from "react-router-dom";

const BREAK_POINT = 800
export const SelfServe = (props: {tellUs?: boolean}) => {
    const navigate = useNavigate()
    const [mobile, setMobile] = useState(window.innerWidth <= BREAK_POINT)
    const {create} = useParams()

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= BREAK_POINT);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    function contact() {
        window.open( "mailto:self@spokenlayer.com", "_blank")
    }

    function tellUs() {
        navigate('/self/tellUs')
    }

    function goCreate(length: string) {
        navigate('/self/' + length)
    }

    function home() {
        navigate('/self/')
    }

    return <div className="self-serve">
        <SelfHeader mobile={mobile} contact={contact} home={home}/>
        {props.tellUs ?
            <SelfTellUs mobile={mobile}/> :
            create ? <SelfCreate mobile={mobile} length={create}/> :
                <SelfWelcome mobile={mobile} create={goCreate} tellus={tellUs}/>}
        <SelfFooter mobile={mobile} contact={contact} tellus={tellUs}/>
    </div>
}