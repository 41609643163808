import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {AuthProvider, RequireAuth} from "react-auth-kit";
import {Login} from "./routes/Login";
import {Root} from "./routes/Root";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {DocumentPage} from "./routes/DocumentPage";
import {OrganizationPage} from "./routes/Organization";
import {EmptyState} from "./routes/EmptyState";
import {Playlist} from "./routes/Playlist";
import {SelfServe} from "./routes/SelfServe";
import {Images} from "./routes/Images";

const sizzlRouter = createBrowserRouter([
    {path: "/login", element: <Login/>},
    {path: "/", element: <RequireAuth loginPath={'/login'}><Root/></RequireAuth>},
    {path: "/d/:id", element: <RequireAuth loginPath={'/login'}><DocumentPage/></RequireAuth>},
    {path: "/o/:id", element: <RequireAuth loginPath={'/login'}><OrganizationPage/></RequireAuth>},
    {path: "/es", element: <RequireAuth loginPath={'/login'}><EmptyState/></RequireAuth>},
    {path: "/pl/:io", element: <Playlist/>},
    {path: "/self", element: <SelfServe/>},
    {path: "/self/tellUs", element: <SelfServe tellUs={true}/>},
    {path: "/self/:create", element: <SelfServe/>},
])

const kitchenRouter = createBrowserRouter([
    {path: "/images", element: <Images/>}
])

function App() {
  const isKitchen = window.location.host.includes("kitchen")

  return (
    <div className="App">
        <GoogleOAuthProvider clientId={'159139330148-tj6nuscu8frjgda3ig5vbq4q6t48lm74.apps.googleusercontent.com'}>
            <AuthProvider authType={'localstorage'} authName={'sl'}>
                <RouterProvider router={isKitchen ? kitchenRouter : sizzlRouter}/>
            </AuthProvider>
        </GoogleOAuthProvider>
    </div>
  )
}

export default App;
