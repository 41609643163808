import "./SelfFooter.scss"
import {ReactComponent as Logo} from "../../assets/self/logo-footer.svg"
import Rule from "../../assets/self/rule.svg"

export const SelfFooter = (props: {mobile: boolean, contact: () => void, tellus: () => void }) => {
    return <div className={"self-footer" + (props.mobile ? " mobile" : "")}>
        <div className="logo-holder" style={ {backgroundImage: `url(${Rule})`} }>
            <Logo className="logo"/>
        </div>
        <div className="bottom">
            <div className="contact">
                <div className="contact-us" onClick={props.contact}>Contact Us</div>
                <div className="tell-us">
                    <div>Have a different kind of project?</div>
                    <div className="cta" onClick={props.tellus}>Tell us.</div>
                </div>
            </div>
            <div className="links">
                <a href="https://policies.spokenlayer.net/privacy-policy/" target="_blank" rel="noreferrer" className="privacy">Privacy</a>
                <div className="copyright">© SpokenLayer. 2024</div>
            </div>
        </div>
    </div>
}