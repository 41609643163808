import "./SelfCreate.scss"
import {ReactComponent as WhatYoullGet} from "../../assets/self/what-youll-get.svg"
import {ReactComponent as WarningCircle} from "../../assets/self/warning-circle.svg"
import {ReactComponent as BGLines} from "../../assets/self/bg-lines.svg"
import {ReactComponent as Triangle} from "../../assets/self/triangle1.svg"
import {Dropdown} from "../../util/Dropdown";
import {useState} from "react";
import {useNetwork} from "../../util/Network";
import {Elements, PaymentElement} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import heroImage from "../../assets/self/hero-image.png"
import {T} from "../../util/Text"
import {useTranslation} from "react-i18next";

const LENGTHS = [{value: "15", label: "15 seconds"}, {value: "30", label: "30 seconds (Market standard)"}, {value: "60", label: "60 seconds"}]

const stripePromise = loadStripe("pk_test_51Oeo07F5Fcv03oreh01mlmxOfFlwb65RoE3Y08a9q32oMPiR1f1A7Xd5OFNqifsQ9MWPu02wK1E6yd2nhJglJ8aZ00RaYKR17u");

export const SelfCreate = (props: {mobile: boolean, length: string}) => {
    const network = useNetwork()
    const {t} = useTranslation("self")
    const [checkingOut, setCheckingOut] = useState<string|undefined>()

    const [error, setError] = useState(false)
    const [length, setLength] = useState(props.length || "30")

    const FIELDS = ["promoting*", "script", "listeners*", "target*", "sound*", "after*", "mandatory", "know", "project*", "email*"]
    const [states, setStates] = useState<Array<string>>(Array(FIELDS.length))

    function checkout() {
        let err = false
        for (let i = 0; i < FIELDS.length; i++) {
            err ||= (FIELDS[i].endsWith("*") && !states[i])
        }
        if (err) {
            setError(true)
        } else {
            network().startPayment([{sku: length, count: 1}]).then(secret => {
                setCheckingOut(secret)
            })
        }
    }

    return <div className={"create" + (props.mobile ? " mobile" : "")}>
        <BGLines className="bg-lines"/>
        {!checkingOut && <><Triangle className="triangle"/><img src={heroImage} className="hero" alt="hero"/></>}
        <div className="what-youll-get">
            <WhatYoullGet/>
            <T name="what" className="title"/>
            <T name="pros" className="body"/>
        </div>
        {checkingOut ? <div className="payment">
            <Elements stripe={stripePromise} options={ {clientSecret: checkingOut, appearance: {theme: "stripe"} } }>
                <PaymentElement/>
                <div className="terms">
                    <input type="checkbox"/>I agree to lorem ipsum dolor sit unum adipiscing quis amet
                </div>
                <div className="buttons">
                    <div className="button cancel">Cancel</div>
                    <div className="button submit">Submit payment</div>
                </div>
            </Elements>
        </div> : <div className="lets-create" key="lets-create">
            <T name="lets-create" className="title"/>
            <T className="description" name="more"/>
            <T className="subtitle" name="how-long"/>
            <Dropdown options={LENGTHS} current={length} onChange={setLength}/>
            {FIELDS.map((field, i) => {
                const name = field.endsWith("*") ? field.substring(0, field.length-1) : field
                const title = t(name), body = t(name+".body", ""), hint = t(name+".hint", ""), note = t(name+".note", "")
                const err = field.endsWith("*") && error && !states[i]
                return <>
                    <div className="subtitle">{title}</div>
                    {body && <div className="body">{body}</div>}
                    <input className="input" defaultValue={states[i]} onChange={(e) => {
                        setError(false)
                        setStates(p => {p[i] = e.target.value; return p})
                    }} placeholder={hint}/>
                    <div className="under">
                        <div className={"error" + (err ? "" : " hidden")}><WarningCircle/>This field is required</div>
                        {note && <div className="note">{note}</div>}
                    </div>
                </>
            })}
        </div>}
        <div className="checkout">
            <div className="title">Your Project:</div>
            <div className="line"><span className="description">{length}-second Audio Ad</span><span>$XXX</span></div>
            <div className="line"><span className="description">1-day Turnaround</span><span>$0</span></div>
            <div className="hr"/>
            <div className="line total"><span className="description">Total</span><span>$XXX</span></div>
            <div className="delivered">Audio delivered in:</div>
            <div className="formats">Industry standard .wav and .mp3.</div>
            {!checkingOut && <div className="button" onClick={checkout}>Proceed to Checkout</div>}
            <T name="need-more"/>
            <T name="one-at-a-time"/>
        </div>
    </div>
}