import "./Status.scss"
import {Story, useNetwork} from "../util/Network";
import {useAuthUser} from "react-auth-kit";
import {useEffect, useRef, useState} from "react";

export const STATUSES = new Map([
    ["not_started", "Not Started"],
    ["in_review", "In Review"],
    ["in_progress", "In Progress"],
    ["delivered", "Delivered"]
])

interface StatusProps {
    story: Story
    updateDocument: (d: Story) => void
}

export const Status = (props: StatusProps) => {
    const role = useAuthUser()()?.role
    const network = useNetwork()
    const [showStatusMenu, setShowStatusMenu] = useState(false)
    const statusCardRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [statusCardRef]);

    function handleClickOutside(e: MouseEvent) {
        const t = e.target
        if (t instanceof Node && statusCardRef.current && !statusCardRef.current.contains(t)) {
            setShowStatusMenu(false)
        }

    }

    async function setStatus(status: string) {
        const document = await network().setStatus(props.story.id, status)
        if (document) {
            props.updateDocument(document)
        }

        setShowStatusMenu(false)
    }

    return <div className={`status ${props.story.status ?? 'not_started'}${role === "admin" ? " hover" : ""}`}>
        <div onClick={() => setShowStatusMenu(true)}>{STATUSES.get(props.story.status ?? 'not_started')}</div>
        {role === "admin" && showStatusMenu && <div className="status-card" ref={statusCardRef} onClick={e => e.stopPropagation()}>
            <div className="item not_started" onClick={() => setStatus("not_started")}>Not Started</div>
            <div className="item in_progress" onClick={() => setStatus("in_progress")}>In Progress</div>
            <div className="item in_review" onClick={() => setStatus("in_review")}>In Review</div>
            <div className="item delivered" onClick={() => setStatus("delivered")}>Delivered</div>
        </div>}
    </div>
}