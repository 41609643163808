import "./SelfTellUs.scss"
import {ReactComponent as WarningCircle} from "../../assets/self/warning-circle.svg"
import {ReactComponent as Unselected} from "../../assets/self/unselected.svg"
import {ReactComponent as Selected} from "../../assets/self/selected.svg"
import {ReactComponent as Error} from "../../assets/self/error.svg"
import {ChangeEvent, useState} from "react";

export const SelfTellUs = (props: {mobile: boolean}) => {
    const [error, setError] = useState(false)
    const [industry, setIndustry] = useState<string | undefined>()
    const [description, setDescription] = useState("")
    const [other, setOther] = useState("")
    const [email, setEmail] = useState("")

    const Industry = (props: {name: string}) => {
        return <div className="industry" onClick={() => setIndustry(props.name)}>
            {industry === props.name ? <Selected/> : (error && (industry === undefined) ? <Error/> : <Unselected/>)}
            {props.name}
        </div>
    }

    function submit() {
        if (description === "" || industry === "" || email === "") {
            setError(true)
        } else {
            // TODO: submit
        }
    }

    function change(f: (v:string) => void) {
        return (e:ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
            setError(false)
            f(e.target.value)
        }
    }

    return <div className={"self-tell-us" + (props.mobile ? " mobile" : "")}>
        <div className="form">
            <div className="title">Have another quick production need?</div>
            <div className="body">We are a full-service production studio, able to cater to any need. We currently serve clients, but are in our first phase of opening up our products to the public.</div>
            <div className="body">Please use this form to tell us what service you need. It will help us prioritize what we open next! 🎉</div>
            <div className="sub-header">Please describe the project you need Audio for.*</div>
            <textarea className="description" placeholder="Enter description." defaultValue={description} onChange={change(setDescription)}/>
            <div className={"error" + (error && description === "" ? "" : " hidden")}><WarningCircle/>This field is required.</div>
            <div className="sub-header">Please choose the industry. *</div>
            <Industry name="Advertising agency"/>
            <Industry name="Digital streaming"/>
            <Industry name="Education and e-learning"/>
            <Industry name="Film and television"/>
            <Industry name="Gaming"/>
            <Industry name="Medical and healthcare"/>
            <Industry name="News and entertainment"/>
            <Industry name="Retail and consumer goods"/>
            <Industry name="Other..."/>
            <input className="other" defaultValue={other} onChange={change(setOther)}/>
            <div className={"error" + (error && description === "" ? "" : " hidden")}><WarningCircle/>Please make a selection.</div>
            <div className="sub-header">Email address</div>
            <div>If you would like to stay informed on our services, please share your email.</div>
            <input className="email" placeholder="Enter your email." defaultValue={email} onChange={change(setEmail)}/>
            <div className={"error" + (error && email === "" ? "" : " hidden")}><WarningCircle/>Please enter a valid email address.</div>
            <div className="button" onClick={submit}>Submit</div>
        </div>
    </div>
}