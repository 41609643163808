import "./DocumentPage.scss"
import {useNavigate, useParams} from "react-router-dom"
import {Header} from "./Header"
import {KeyboardEvent, useEffect, useRef, useState} from "react"
import {Story, useNetwork} from "../util/Network"
import {useAuthUser, useSignIn} from "react-auth-kit";
import {Status} from "../components/Status";
import {Img} from "react-image";
import {ProfilePicture} from "../components/ProfilePicture";
import {Assets} from "../components/Assets";
import {CommentTile} from "../components/CommentTile";
import {formatInTimeZone} from 'date-fns-tz'
import {DayPicker} from "react-day-picker";
import 'react-day-picker/dist/style.css';

export const DocumentPage = () => {
    const network = useNetwork()
    const signIn = useSignIn()
    const navigate = useNavigate()
    const user = useAuthUser()()
    const { id } = useParams()
    const [document, setDocument] = useState<Story | undefined>(undefined)
    const newCommentRef = useRef<HTMLInputElement>(null)
    const [editDue, setEditDue] = useState(false)

    useEffect(() => {
        if (id) {
            network().getDocument(id).then(d => {
                setDocument(d)
                network().refreshToken().then(resp => {
                    if (resp) {
                        signIn({
                            token: resp.token,
                            tokenType: "Bearer",
                            expiresIn: 60 * 24 * 30,
                            authState: resp.user
                        })
                    }
                })
            })
        }
    }, [id])

    async function newCommentKeyDown(e : KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            const text = newCommentRef.current?.value
            if (id && text) {
                const document = await network().addComment(id, text)
                if (document) {
                    setDocument(document)
                    newCommentRef.current.value= ""
                }
            }
        }
    }

    function updateDue(d?: Date) {
        if (d && document) {
            d.setUTCHours(0)
            network().setDue(document.id, d.getTime() / 1000).then(d => {
                if (d) {
                    setDocument(d)
                }
            })
            setEditDue(false)
        }
    }

    return <div className="document">
        <Header/>
        {document && <>
            <div className="canvas">
                <div className="project" onClick={() => {navigate(`/o/${document?.project}`)}}>{document.project} »</div>
                <div className="details-row">
                    <Img src={[document.image ?? "/placeholder.png", "/placeholder.png"]} alt="cover" onClick={() => window.open(document.url, "_blank", "noreferrer")}/>
                    <div className="details">
                        <div className="title" onClick={() => window.open(document.url, "_blank", "noreferrer")}>{document.title}</div>
                        <div className="row">
                            <Status story={document} updateDocument={setDocument}/>
                            {(document.status !== "delivered") && (user?.role === "admin") && <div>
                                <div className="due" onClick={_ => {setEditDue(!editDue)}}>
                                    {document.due ? ("Due " + formatInTimeZone(new Date(document.due * 1000), "UTC", "EEE, MMMM d")) :
                                        "Due date not set"}
                                </div>
                                {editDue && <DayPicker className="picker" mode="single" selected={new Date((document.due ?? 0) * 1000)} onSelect={updateDue}/>}
                            </div>}
                        </div>
                    </div>
                </div>

                <Assets story={document} setDocument={setDocument}/>

                <div className="comments-title">Comments:</div>
                <div className="comments">
                    {document.comments?.map((c, i) => <CommentTile comment={c} story={document} setDocument={setDocument} key={`comment_${i}`}/>)}
                    {(document.comments?.length ?? 0) === 0 && <div className="no-comments">
                        No comments yet.
                    </div>}
                    <div className="add-comment">
                        <ProfilePicture image={user?.image} uid={user?.id}/>
                        <input type="text" className="text" id="newcomment" ref={newCommentRef} placeholder="Leave a comment..." onKeyDown={newCommentKeyDown}/>
                    </div>
                </div>
            </div>
        </>}
    </div>
}

