import {Img} from "react-image";

const placeholders = [
    "/avatar-01.png",
    "/avatar-02.png",
    "/avatar-03.png",
    "/avatar-04.png",
    "/avatar-05.png"
]

export const ProfilePicture = (props: {image?: string, uid?: string, className?: string}) => {
    const fallback = placeholders[(props.uid?.charCodeAt(0) ?? 0) % placeholders.length]
    return <Img src={[props.image ?? fallback, fallback]} alt="profile" className={props.className ?? "profile"} referrerPolicy="no-referrer"/>
}