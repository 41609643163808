import "./SelfHeader.scss"
import {ReactComponent as Logo} from "../../assets/self/logo.svg"
import {ReactComponent as By} from "../../assets/self/bySL-logo.svg"

export const SelfHeader = (props: {mobile: boolean, contact: () => void, home: () => void }) => {
    return <div className={"self-header" + (props.mobile ? " mobile" : "")}>
        <Logo className="logo" onClick={props.home}/>
        <By className="by" onClick={props.home}/>
        <div className="spacer"/>
        <div className="contact" onClick={props.contact}>Contact Us</div>
    </div>
}