import "./Login.scss"

import {GoogleLogin} from "@react-oauth/google"
import {ReactComponent as Logo} from "../assets/logo-v.svg"
import Lottie from "lottie-react"
import animation from "../assets/wave_animation.json"
import {useRef, useState} from "react";
import {useSignIn} from "react-auth-kit";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useNetwork} from "../util/Network";

export const Login = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string|undefined>(undefined)
    const signIn = useSignIn()
    const navigate = useNavigate()
    const network = useNetwork()
    const emailRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const repeatPasswordRef = useRef<HTMLInputElement>(null)
    const [forgot, setForgot] = useState(0)

    const [searchParams] = useSearchParams()
    const passwordKey = searchParams.get("k")
    const reset = searchParams.get("reset")

    function doLogin(resp: any) {
        if (resp && signIn({
            token: resp.token,
            tokenType: "Bearer",
            expiresIn: 60 * 24 * 30,
            authState: resp.user
        })) {
            navigate("/")
        } else {
            setError("Login error. Either we do not have an account for you or you typed your password incorrectly.")
        }
        setLoading(false)
    }

    function send() {
        setError(undefined)
        if (forgot) {
            const email = passwordRef.current?.value
            if (email) {
                network().forgotPassword(email).then(_ => setForgot(2))
            } else {
                setError("Please enter a valid email address.")
            }
        } else {
            const newPassword = passwordRef.current?.value
            if (!newPassword || newPassword !== repeatPasswordRef.current?.value)
                setError("Passwords must match.")
            else {
                setLoading(true)
                network().resetPassword(passwordKey ?? "", newPassword).then(doLogin)
            }
        }
    }

    function login() {
        setError(undefined)
        setLoading(true)
        network().login(emailRef.current?.value ?? "", passwordRef.current?.value ?? "").then(doLogin)
    }

    return <div className="login">

        <svg width="148" height="202" viewBox="0 0 148 202" fill="none" xmlns="http://www.w3.org/2000/svg" className="polytl1"><path d="M148 101L0.25 201.459V0.541054L148 101Z" fill="#70F4CC"/></svg>
        <svg width="111" height="152" viewBox="0 0 111 152" fill="none" xmlns="http://www.w3.org/2000/svg" className="polytl2"><path d="M111 76L0 151.344L0 0.655792L111 76Z" fill="#F7D2C7"/></svg>

        <svg width="80" height="109" viewBox="0 0 80 109" fill="none" xmlns="http://www.w3.org/2000/svg" className="polybr1"><path d="M80 54.5L0.5 108.627L0.5 0.373413L80 54.5Z" fill="#F7D2C7"/></svg>
        <svg width="148" height="202" viewBox="0 0 148 202" fill="none" xmlns="http://www.w3.org/2000/svg" className="polybr2"><path d="M148 101L0.25 201.459V0.541054L148 101Z" fill="#70F4CC"/></svg>
        <svg width="228" height="263" viewBox="0 0 228 263" fill="none" xmlns="http://www.w3.org/2000/svg" className="polybr3"><rect x="198.975" width="281.392" height="6.544" transform="rotate(45 198.975 0)" fill="black"/><rect x="179.309" y="19.666" width="281.392" height="6.544" transform="rotate(45 179.309 19.666)" fill="black"/><rect x="159.643" y="39.332" width="281.392" height="6.544" transform="rotate(45 159.643 39.332)" fill="black"/><rect x="141.133" y="57.8418" width="281.392" height="6.544" transform="rotate(45 141.133 57.8418)" fill="black"/><rect x="121.467" y="77.5078" width="281.392" height="6.544" transform="rotate(45 121.467 77.5078)" fill="black"/><rect x="101.801" y="97.1738" width="281.392" height="6.544" transform="rotate(45 101.801 97.1738)" fill="black"/><rect x="82.1348" y="116.84" width="281.392" height="6.544" transform="rotate(45 82.1348 116.84)" fill="black"/><rect x="62.4688" y="136.506" width="281.392" height="6.544" transform="rotate(45 62.4688 136.506)" fill="black"/><rect x="43.96" y="155.015" width="281.392" height="6.544" transform="rotate(45 43.96 155.015)" fill="black"/><rect x="24.293" y="174.682" width="281.392" height="6.544" transform="rotate(45 24.293 174.682)" fill="black"/><rect x="4.62695" y="194.348" width="281.392" height="6.544" transform="rotate(45 4.62695 194.348)" fill="black"/></svg>
        <svg width="202" height="202" viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg" className="polybr4"><rect y="101.484" width="142.142" height="142.142" transform="rotate(-45 0 101.484)" fill="#EC795C"/></svg>

        <svg width="204" height="204" viewBox="0 0 204 204" fill="none" xmlns="http://www.w3.org/2000/svg" className="polytr2"><rect width="204" height="204" fill="#9557F0"/></svg>
        <svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg" className="polytr1"><rect width="105" height="105" fill="black"/></svg>

        <svg width="418" height="418" viewBox="0 0 418 418" fill="none" xmlns="http://www.w3.org/2000/svg" className="polybl4"><rect y="209.25" width="295.04" height="295.04" transform="rotate(-45 0 209.25)" fill="#EC795C"/></svg>
        <svg width="204" height="204" viewBox="0 0 204 204" fill="none" xmlns="http://www.w3.org/2000/svg" className="polybl3"><rect width="204" height="204" fill="#9557F0"/></svg>
        <svg width="209" height="172" viewBox="0 0 209 172" fill="none" xmlns="http://www.w3.org/2000/svg" className="polybl2"><path fillRule="evenodd" clipRule="evenodd" d="M0 0H209V4H0V0ZM0 17H209V21H0V17ZM209 34H0V38H209V34ZM0 50H209V54H0V50ZM209 67H0V71H209V67ZM0 84H209V88H0V84ZM209 101H0V105H209V101ZM0 118H209V122H0V118ZM209 134H0V138H209V134ZM0 151H209V155H0V151ZM209 168H0V172H209V168Z" fill="black"/></svg>
        <svg width="283" height="283" viewBox="0 0 283 283" fill="none" xmlns="http://www.w3.org/2000/svg" className="polybl1"><path d="M0 141.421L141.421 0L144.71 3.28887L3.28887 144.71L0 141.421Z" fill="black"/><path d="M13.9775 155.399L155.399 13.9775L158.688 17.2664L17.2664 158.688L13.9775 155.399Z" fill="black"/><path d="M169.376 27.9551L27.9551 169.376L31.2439 172.665L172.665 31.2439L169.376 27.9551Z" fill="black"/><path d="M41.1113 182.533L182.533 41.1113L185.822 44.4002L44.4002 185.822L41.1113 182.533Z" fill="black"/><path d="M196.51 55.0889L55.0889 196.51L58.3777 199.799L199.799 58.3777L196.51 55.0889Z" fill="black"/><path d="M69.0664 210.488L210.488 69.0664L213.777 72.3553L72.3553 213.777L69.0664 210.488Z" fill="black"/><path d="M224.465 83.0439L83.0439 224.465L86.3328 227.754L227.754 86.3328L224.465 83.0439Z" fill="black"/><path d="M97.0215 238.443L238.443 97.0215L241.732 100.31L100.31 241.732L97.0215 238.443Z" fill="black"/><path d="M251.598 110.177L110.177 251.598L113.466 254.887L254.887 113.466L251.598 110.177Z" fill="black"/><path d="M124.154 265.577L265.576 124.155L268.865 127.444L127.443 268.866L124.154 265.577Z" fill="black"/><path d="M279.554 138.133L138.133 279.554L141.422 282.843L282.843 141.422L279.554 138.133Z" fill="black"/></svg>

        <div className="frame">
            {passwordKey || forgot ? <> {/* Password reset */}
                <Logo/>
                <div className="set-password-title">{forgot || reset ? "Password Reset" : "Set Password"}</div>
                {forgot > 0 && <div className="forgot-prompt">
                    {forgot === 1 ? "Enter the email address that you used to register. We’ll send you an email with a link to reset your password.":"We've sent you an email. Just follow the instructions to reset your password."}
                </div>}
                {forgot > 0 ? <>
                    {forgot === 1 && <>
                    <div className="password-title">email Address</div>
                    <input type="text" className="password" ref={passwordRef} placeholder="Enter email address"/>
                    </>}
                </>:<>
                    <div className="password-title">New password</div>
                    <input type="password" className="password" ref={passwordRef} placeholder="Enter password"/>
                    <div className="password-title">Repeat new password</div>
                    <input type="password" className="password" ref={repeatPasswordRef} placeholder="Enter password"/>
                </>}
                {forgot !== 2 && <>
                    {error && <div className="error password-error">{error}</div>}
                    <div className="log-in" onClick={send}>Send</div>
                </>}
            </>:<>
                {/* Normal Login */}
                <div className="title">
                    Log in to <Logo className="logo"/>
                </div>
                <div className="text">
                    Premium videos to make your brand stand out. No effort, no friction.
                </div>
                <div className="email-title">email</div>
                <input type="text" className="email" ref={emailRef} placeholder="Enter email"/>
                <div className="password-title">Password</div>
                <input type="password" className="password" ref={passwordRef} placeholder="Enter password"/>
                <div className="forgot-password" onClick={e => setForgot(1)}>Forgot password?</div>
                <div className="log-in" onClick={login}>Log in</div>
                {error && <div className="error">{error}</div>}

                <div className="or"><div className="hr"/>Or<div className="hr"/></div>

                <div className="button">
                    <GoogleLogin
                        type={'standard'}
                        onSuccess={async credentialResponse => {
                            if (credentialResponse.credential) {
                                setLoading(true)
                                network().googleLogin(credentialResponse.credential).then(doLogin)
                            }
                        }}
                        onError={() => {
                            setLoading(false)
                        }}
                    />
                </div>
            </>}
        </div>
        { loading && <div className="loading">
            <Lottie className="animation" animationData={animation} loop={true}/>
        </div> }
    </div>
}