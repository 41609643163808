import "./CommentTile.scss"

import {useRef, useState} from "react";
import {ProfilePicture} from "./ProfilePicture";
import {formatDistanceToNow} from "date-fns";
import {Comment, Story, useNetwork} from "../util/Network"
import {ReactComponent as More} from "../assets/more.svg"
import {useAuthUser} from "react-auth-kit";

interface CommentTileProps {
    comment: Comment
    story: Story
    setDocument: (d: Story) => void
}
export const CommentTile = (props : CommentTileProps) => {
    const network = useNetwork()
    const user = useAuthUser()()
    const [comment, setComment] = useState<string | undefined>(undefined)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [edit, setEdit] = useState(false)
    const editorRef = useRef<HTMLInputElement>(null)

    async function deleteComment() {
        if (props.story.id) {
            const document = await network().deleteComment(props.story.id, props.comment.id)

            if (document) {
                props.setDocument(document)
            }
        }
    }

    async function update() {
        const newComment = editorRef.current?.value
        if (props.story.id && newComment && newComment !== props.comment.text) {
            const document = await network().updateComment(props.story.id, props.comment.id, props.comment.text, newComment)

            if (document) {
                props.setDocument(document)
            }
        }
        setEdit(false)
        setComment(undefined)
    }

    const open = (comment === props.comment.id) && !edit

    return <div className="comment">
        <ProfilePicture image={network().userImageUrl(props.comment.user)} uid={props.comment.user}/>
        <div className="content">
            <div className="details">
                <div>{props.comment.userName} • <span
                    className="date">{formatDistanceToNow(props.comment.timestamp * 1000, {addSuffix: true})}</span></div>
                {props.comment.user === user?.id && <div className={"comment-menu" + (open ? " open" : "")}>
                    <More onClick={() => {
                        setComment(open ? undefined : props.comment.id)
                        setEdit(false)
                        setConfirmDelete(false)
                    }}/>
                    <div className={"menu" + (open && !confirmDelete ? "" : " hide")}>
                        <div className="menu-item edit" onClick={() => {
                            setEdit(true)
                        }}>Edit comment</div>
                        <div className="menu-item delete" onClick={() => setConfirmDelete(true)}>Delete comment
                        </div>
                    </div>
                    <div className={"delete-confirm" + (open && confirmDelete ? "" : " hide")}>
                        <div className="message">Are you sure you want to delete this comment?</div>
                        <div className="buttons">
                            <div className="button cancel" onClick={() => {
                                setConfirmDelete(false)
                                setEdit(false)
                                setComment(undefined)
                            }}>Cancel
                            </div>
                            <div className="button delete" onClick={deleteComment}>Delete</div>
                        </div>
                    </div>
                </div>}
            </div>
            {edit ? <>
                    <input className="editor" defaultValue={props.comment.text} ref={editorRef} autoFocus={true}/>
                    <div className="edit-buttons">
                        <div className="button cancel" onClick={() => {
                            setEdit(false)
                            setComment(undefined)
                        }}>Cancel</div>
                        <div className="button save" onClick={update}>Save</div>

                    </div>
                </>:
                <div className="text">{props.comment.text}</div>
            }
        </div>
    </div>;
}
