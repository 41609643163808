import {useTranslation} from "react-i18next";
import {MouseEventHandler} from "react";

interface TProps {
    name: string
    className?: string
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export const T = (props: TProps) => <div className={props.className ?? props.name} onClick={props.onClick}>{useTranslation("self").t(props.name).split("\n").map((v, i) => i === 0 ? v : <><br/>{v}</>)}</div>

export const S = (props: TProps) => <span className={props.className ?? props.name} onClick={props.onClick}> {useTranslation("self").t(props.name).split("\n").map((v, i) => i === 0 ? v : <><br/>{v}</>)}</span>